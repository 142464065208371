/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:e8134070-e039-4562-92c6-f17f7bc7ba56",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ym3o46U0Z",
    "aws_user_pools_web_client_id": "1ivc21330occ6mm3289s7fmqet",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://wqkijqvhrffxvnyevnorbholvq.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
};


export default awsmobile;
