import styles from './css/Calendar.module.scss'
import './css/Tooltip.scss'
import HabitRow from './HabitRow'
import HabitInput from './HabitInput'
import { days, firstDayOfWeek, lastWeek, nextWeek, isToday, toIsoDate } from './DateHelper'

import React from 'react';

class Calendar extends React.Component {
  constructor(props) {
    super(props);

    let startDate = new Date();
    startDate = firstDayOfWeek(startDate);

    this.state = {
      addingHabit: false,
      i: 0,
      j: 0,
      startDate: startDate,
      reordering: false,
      editing: false,
      editTarget: null,
      selected: null,
      habits: []
    };
    this.startEditing = this.startEditing.bind(this);
    this.back = this.back.bind(this);
    this.forward = this.forward.bind(this);
    this.swap = this.swap.bind(this);
    this.keydownListener = this.keydownListener.bind(this);
  }

  back() {
    this.setState({ startDate: lastWeek(this.state.startDate) });
  }

  forward() {
    this.setState({ startDate: nextWeek(this.state.startDate) });
  }

  stopReordering() {
    this.props.swapHabits(this.state.i, this.state.j);
    this.setState({ reordering: false });
  }
  startEditing(habit) {
    this.setState({editTarget: habit, editing: true, addingHabit: false})
  }
  startReordering(selected) {
    let habits = [...this.props.habits];
    let index = habits.indexOf(selected);
    this.setState({
      i: index,
      j: index,
      habits: habits,
      reordering: true,
      selected: selected
    });
  }

  swap(i) {
    let habits = [...this.state.habits];
    let index = habits.indexOf(this.state.selected);
    habits.splice(index, 1);
    habits.splice(i, 0, this.state.selected);
    this.setState({ j: i, habits: habits })
  }


  buildHeader() {
    let tableHeader = [];
    let date = new Date(this.state.startDate);
    for (let i = 0; i < 7; i++) {
      let classes = [styles.dateHeader, isToday(date) ? styles.today : ""].join(' ');
      let dateLabel = date.getDate();
      if (i === 0) {
        dateLabel = `${date.getMonth() + 1}/${dateLabel}`;
      }
      tableHeader.push((<td key={`date-header-${toIsoDate(date)}`} className={classes}>{dateLabel}<br />{days[date.getDay()]}</td>));
      date.setDate(date.getDate() + 1);
    }
    return tableHeader;
  }
  componentDidMount() {
    document.addEventListener("keydown", this.keydownListener, false);
  }
  keydownListener(e) {
    if (e.key === "Escape" || e.key === "Esc") {
      
      this.setState({addingHabit: !this.state.addingHabit && !this.state.editing, editing: false});
    }
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.keydownListener);
  }
  render() {
    let habitRows = [];

    if (this.state.reordering) {
      for (let i = 0; i < this.state.habits.length; i++) {
        let isSelected = this.state.habits[i] === this.state.selected;
        habitRows.push((
          <HabitRow
            selected={isSelected}
            onMouseEnter={isSelected ? null : () => this.swap(i)}
            updateHabit={this.props.updateHabit}
            deleteHabit={this.props.deleteHabit}
            toggleCheck={this.props.toggleCheck}
            habit={this.state.habits[i]}
            startDate={this.state.startDate}
            key={`habit-row-${this.state.habits[i].id}`}
          />));
      }
    } else {
      for (let i = 0; i < this.props.habits.length; i++) {
        habitRows.push((
          <HabitRow
            selected={false}
            startEditing={this.startEditing}
            disabled={this.state.editing && this.props.habits[i].id === this.state.editTarget.id }
            onClick={(e) => { e.preventDefault(); this.startReordering(this.props.habits[i]) }}
            updateHabit={this.props.updateHabit}
            deleteHabit={this.props.deleteHabit}
            toggleCheck={this.props.toggleCheck}
            habit={this.props.habits[i]}
            startDate={this.state.startDate}
            key={`habit-row-${this.props.habits[i].id}`}

          />));
      }
    }
    return (
      <>
        {this.state.addingHabit && <HabitInput hide={() => this.setState({ addingHabit: false })} submit={this.props.addHabit} />}
        {this.state.editing && <HabitInput habit={this.state.editTarget} hide={() => this.setState({ editing: false })} 
          submit={(habitName, habitTarget, habitRepeat)=>{this.props.updateHabit(this.state.editTarget.id,habitName,habitTarget,habitRepeat )}} />}
        
        <div
      
          className={styles.calendar}>
          <div className={styles.calendarButtons} >
            
            
            <a  href="" onClick={(e) => (e.preventDefault(), this.back())}> {'<'} last week</a>
            
            {(!this.state.addingHabit && !this.state.editing) && <a className="hasTooltip" href="" onClick={(e) => (e.preventDefault(), this.setState({ addingHabit: true }))} >+habit <span className="tooltip">[esc]</span></a>}
            
            <a href="" onClick={(e) => (e.preventDefault(), this.forward())}>next week {'>'} </a>
         </div>
         </div>

      <div className={styles.tableWrapper}>

          <table   cellPadding="0" cellSpacing="0" className={(this.state.addingHabit || this.state.editing) ? styles.addingHabit : ""}     onMouseUp={() => {  if (this.state.reordering) this.stopReordering() }}
          onMouseLeave={() => { if (this.state.reordering) this.stopReordering() }}>
            <thead className={styles.calendarHeader}>
              <tr>
                <td></td>
                <td></td>
                {this.buildHeader()}
                <td colSpan={5}className={styles.spacer}></td>
              </tr>
            </thead>
            <tbody>
              {habitRows}
            </tbody>
          </table>
          </div>
      </>);
  }

}

export default Calendar;