import styles from './css/HabitRow.module.scss';

import CheckCell from './CheckCell'
import { toIsoDate, fromIsoDate, firstDayOfMonth, nextMonth, nextWeek } from './DateHelper'

import React from 'react';

class HabitRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { editing: false, isMouseIn: false }
    this.cancelEditing = this.cancelEditing.bind(this);
  }

  cancelEditing() {
    this.setState({ editing: false });
  }

  countCompleted() {
    let from, to;
    from = this.props.startDate;
    to = new Date(nextWeek(from));
    return this.props.habit.done.filter(o => (from < fromIsoDate(o) && fromIsoDate(o) < to)).length;
  }

  render() {


    let checkCells = [];
    let date = new Date(this.props.startDate);
    let today = new Date();


    //clean this up, maybe stick the code into fromISO()

    let createdAt = new Date(fromIsoDate(this.props.habit.createdAt.split("T")[0]).toDateString());

    for (let i = 0; i < 7; i++) {
      // if (date > today ||  date <= createdAt) {
      //   checkCells.push((<td className={styles.invalidDateCheck}>•</td>));
      // } else {
      let checkIndex = this.props.habit.done.findIndex(o => o == toIsoDate(date));
      checkCells.push((
        <CheckCell
          key={this.props.habit.id + toIsoDate(date)}
          date={new Date(date)}
          isChecked={checkIndex != -1}
          habitId={this.props.habit.id}
          over={this.state.isMouseIn || this.props.selected}
          toggleCheck={this.props.toggleCheck}

        />
      ));
      // }
      date.setDate(date.getDate() + 1);
    }

    let className = styles.habitRow;
    let style = {};
    if (this.props.selected) {
      className = styles.selected;
      //not certain why this is necessary.
      //.selected already includes the line 
      //  cursor: grabbing;
      //but it seems like something else is overriding it
      style = { cursor: "grabbing" };
    } else if (this.props.disabled) {
      return (
        <tr ><td></td><td></td><td colSpan={7} className={styles.disabled} style={{ fontSize: "1rem", backgroundColor:"black" }}>[editing]</td><td colSpan={5}></td></tr>
      );
    }



    return (
      <tr className={className} style={style}
        onMouseUp={() => this.setState({ isMouseIn: true })}
        onMouseEnter={this.props.onMouseEnter ? this.props.onMouseEnter : () => this.setState({ isMouseIn: true })}
        onMouseLeave={() => this.setState({ isMouseIn: false })}>
        <td>
        </td>
        <td className={styles.habitLabel}>
          {this.props.habit.name}
        </td>

        {checkCells}

        <ProgressInfo completed={this.countCompleted()} target={this.props.habit.target} />
        <td className={styles.toolButton} style={style} onClick={() => (this.setState({ isMouseIn: false }), this.props.startEditing(this.props.habit))} >
          {this.state.isMouseIn ? "✎" : ""}
        </td>
        <td className={styles.toolButton} style={style} onClick={() => this.props.deleteHabit(this.props.habit)}>
          {this.state.isMouseIn ? "×" : ""}
        </td>
        <td className={styles.handle} style={style} onMouseDown={this.props.onClick}>
          {this.state.isMouseIn ? "↕" : ""}
        </td>
      </tr>);
  }
}

class ProgressInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isMouseIn: false }
  }

  render() {
    return (
      <td
        className={styles.progress}
        onMouseEnter={() => this.setState({ isMouseIn: true })}
        onMouseLeave={() => this.setState({ isMouseIn: false })}
      >
        {this.state.isMouseIn
          ? <>{this.props.completed}/{this.props.target}</>
          : <>{Math.trunc(100 * this.props.completed / this.props.target)}%</>}

      </td>
    );
  }
}

export default HabitRow;
