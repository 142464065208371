import '../css/App.scss';
import styles from '../css/AccountManagement.module.scss';

import React from 'react';
import { Auth } from 'aws-amplify';
import ReactModal from 'react-modal';

import { withAuthenticator } from 'aws-amplify-react';
import { toast, ToastContainer} from 'react-toastify';

function toastAccountManagementInfo(info) {
  const n = () => {
    return (
      <div style={{ fontSize: "1rem" }}>
        <p>{info}</p>
      </div>
    )
  }

  toast.success(n, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

}


function toastAccountManagementError(error) {
  const n = () => {
    return (
      <div style={{ fontSize: "1rem" }}>
        <p>{error}</p>
      </div>
    )
  }
  toast.error(n, {
    position: "bottom-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    });

}

class AccountSettings extends React.Component {
  componentDidMount() {
    document.title = "Habit Tracker - account settings";
  }
  
  render() {
    return (
      <>
        <nav>
          <ul>
            <li><a href="" onClick={(e) => (e.preventDefault(), Auth.signOut())}>sign out</a></li>
            <li><a href="" onClick={(e)=>(e.preventDefault(), this.props.toHome())}>back</a></li>

          </ul>
        </nav>
        <body>
          {/* TODO: clean up the CSS, names, etc. Small changes break this. */}
          <div className={"app " + styles.accountManagement}>
            <h1>Settings</h1>
            <PasswordForm />
            <EmailForm />
            <DeleteAccountButton history={this.props.history} />
            <ToastContainer/>
          </div>
        </body>
      </>
    );
  }
}

class DeleteAccountButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {deleteAccountModalOpen: false}
    this.deleteAccount = this.deleteAccount.bind(this);
  }
  

  deleteAccount(event) {
    // event.preventDefault();
    // if (window.confirm('You are about to delete your account. If you choose to do so, none of your data will be recoverable. Are you sure you wish to continue?'))
    //   Auth.currentAuthenticatedUser({
    //     bypassCache: true  
    //   }).then((user) => {
    //     user.deleteUser((error, data) => {
    //       if (error) {
    //         throw error;
    //       }
    //       Auth.signOut({ global: true });
    //     });
    //   }).catch(err => console.log(err));
  }

  render() {
    return (
      <>
        <ReactModal isOpen={this.state.deleteAccountModalOpen} 
          style={{overlay: {}, content:{overflowY: "hidden",backgroundColor:"rgba(0,0,0,0)"}}}
        >
          
          <div style={{height:"100vh",display:"flex", alignItems:"center", justifyContent:"center"}}>
            <div style={{ backgroundColor: "white",color:"black", padding:"1em",display:"inline-block"}}>
              You are about to delete your account. If you choose to do so, none of your data will be recoverable. Are you sure you wish to continue?
              <br/>
              <input type="button" value="yes, permanently delete my account" onClick={this.deleteAccount}/>
              <input type="button" value="no" onClick={()=>this.setState({deleteAccountModalOpen: false})}/>
            </div>
          </div>
        </ReactModal> 
        
        <form>
        <h5>Delete Account</h5>
          <input style={{ backgroundColor: "red" }} type="button" value="Delete" onClick={()=>this.setState({deleteAccountModalOpen: true})} />
        </form>
      </>
    );
  }
}

class PasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { notif: "" };
    this.changePassword = this.changePassword.bind(this);
  }

  changePassword(event) {
    let currentPassword = this.state.currentPassword;
    let newPassword = this.state.newPassword;
    Auth.currentAuthenticatedUser()
      .then(user => {
        return Auth.changePassword(user, currentPassword, newPassword);
      })
      .then(data => {
        toastAccountManagementInfo("Password changed successfully.");
      })
      .catch(err => {
        console.log("err:");
        console.log(err);
        if (err.message.includes("previousPassword") || err.message.includes("Incorrect") ) {
          toastAccountManagementError("Current password incorrect.");
        }        
        if (err.message.includes("proposedPassword")) {
          toastAccountManagementError("New password must be at least 8 characters.");
        }
        if (err.message.includes("Attempt limit exceeded")) {
          toastAccountManagementError(err.message);
        }  
    });
    this.setState({ currentPassword: '', newPassword: '' });
    event.preventDefault();
  }
  render() {
    return (
      <>
        <form onSubmit={this.changePassword}>
          <h5>Change Password</h5>
          <label for="currentPassword">current password</label> <br />
          <input id="currentPassword" type="password" value={this.state.currentPassword} onChange={(e) => this.setState({ currentPassword: e.target.value })} />
          <br />
          <label for="newPassword">new password</label><br />
          <input id="newPassword" type="password" value={this.state.newPassword} onChange={(e) => this.setState({ newPassword: e.target.value })} />
          <br />
          <input type="submit" value="Submit" />
        </form>
      </>
    )

  }
}

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { waitingOnVerify: false };
    this.changeEmail = this.changeEmail.bind(this);
    this.verify = this.verify.bind(this);
  }

  async changeEmail(event) {
    event.preventDefault();
    let newEmail = this.state.newEmail;
    try {
      let user = await Auth.currentAuthenticatedUser();
      let result = await Auth.updateUserAttributes(user, {
        'email': newEmail,
      });
      this.setState({  waitingOnVerify: true })
    } catch (err) {
      toastAccountManagementError("Invalid email.");
    }
  }
  
  async verify(event) {
    event.preventDefault();
    let code = this.state.code;
    try {
      let result = await Auth.verifyCurrentUserAttributeSubmit('email', code);
      console.log(result);
      toastAccountManagementInfo("Email changed successfully.");

      this.setState({ code: "", waitingOnVerify: false })
    } catch (err) {
      toastAccountManagementError("Incorrect verification code.");
    }

  }

  render() {
    let content;
    if (this.state.waitingOnVerify)
      content =
        <>
          <form onSubmit={this.verify}>
            <h5>Change Email</h5>
            <p>A verification code has been sent to {this.state.newEmail}. Be sure to check your spam folder.</p>
            <label for="verificationCode">verification code</label> <br />
            <input id="verificationCode" type="text" value={this.state.code} onChange={(e) => this.setState({ code: e.target.value })} />
            <br/>
            <input type="submit" value="Submit" />
          </form>
        </>
    else
      content =
        <>
          <form onSubmit={this.changeEmail}>
            <h5>Change Email</h5>
            <label for="newEmail">new email</label><br />
            <input id="newEmail" type="text" value={this.state.newEmail} onChange={(e) => this.setState({ newEmail: e.target.value })} /><br />
            <input type="submit" value="Submit" />
          </form >
        </>
    return content;

  }
}



// export default AccountSettings;
export default withAuthenticator(AccountSettings, false);
