
import AccountManagement from './pages/AccountManagement';
import HomePage from './pages/HomePage';

import React from 'react';

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {atHomePage: true };
    }   
    render() {
        if (this.state.atHomePage) {
            return <HomePage toAccountSettings={()=>this.setState({atHomePage:false})}/>;
        } else if (!this.state.atHomePage) {
            return <AccountManagement toHome={()=>this.setState({atHomePage:true})}/>;
        }
    }
}

export default App;