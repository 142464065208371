import styles from './css/HabitInput.module.scss';
import './css/Tooltip.scss';

import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class HabitInput extends React.Component {
  constructor(props) {
    super(props);
    if (this.props.habit === undefined) {
      this.state = {
        editing: false,
        habitName: "",
        habitTarget: "",
      };
    } else {
      this.state = {
        editing: true,
        habitName: this.props.habit.name,
        habitTarget: this.props.habit.target,
      };
    }
    this.keypressListener = this.keypressListener.bind(this);
    this.validateAndSubmit = this.validateAndSubmit.bind(this);
  }

  keypressListener(e) {
    if (e.key === "Enter") {
      this.validateAndSubmit();
    }
  }

  componentDidMount() {
    document.addEventListener("keypress", this.keypressListener, false);
    this.habitNameInput.focus();  
  }

  componentWillUnmount() {
    document.removeEventListener("keypress", this.keypressListener);
  }


  showInfoToast(info) {
    const n = () => {
      return (<p style={{fontSize:"1rem"}}>{info}</p>)
    }
    toast.error(n, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: false,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      });
  }

  validateAndSubmit() {
    if (this.state.habitName.length === 0) {
      this.showInfoToast("The habit name cannot be empty.");
      return;
    }
    if (this.state.habitName.length > 64) {
      this.showInfoToast("The habit name cannot be more than 64 characters.");
      return;
    }
    let target = parseInt(this.state.habitTarget)
    if (!isNaN(target) && Number.isInteger(target) ) {
      if (target < 1 || target > 7) {
        this.showInfoToast("The weekly target must be between 1 and 7 days.");
        return;
      }
    } else {
      this.showInfoToast("The weekly target must be a positive integer.");
      return;
    }
    

    this.props.submit(this.state.habitName, this.state.habitTarget, this.state.habitRepeat);
    
    if (this.state.editing) {
      this.props.hide();
    } else {
      this.setState({ habitName: "", habitTarget: "", habitRepeat: "week" });
      this.habitNameInput.focus();
    }          
  }

  render() {
    return (
      <div className={styles.habitInput}>
        <a  href="" onClick={(e) => (e.preventDefault(), this.props.hide())} className={styles.hideButton + " hasTooltip"}>{this.state.editing ? "cancel" : "hide"}<span className="tooltip">[esc]</span></a>
        {this.state.editing ? `Editing habit "${this.props.habit.name}" (${this.props.habit.target}/week)` : "create a new habit"} 
        <br/>
        <br/>
        I want to &nbsp;
        <input
          onChange={event => this.setState({ habitName: event.target.value })}
          value={this.state.habitName}
          placeholder="habit name"
          value={this.state.habitName}
          ref={e => (this.habitNameInput = e)}
        />
          &nbsp;
        <input
          value={this.state.habitTarget}
          type="number"
          placeholder="goal"
          onChange={event => this.setState({ habitTarget: event.target.value })} 
          ref={e => (this.habitTargetInput = e)}  
        />
          &nbsp; time(s) every week. 
          
          {/* &nbsp;
        <select onChange={event => this.setState({ habitRepeat: event.target.value })} value={this.state.habitRepeat}>
          <option value="week">week</option>
          <option value="month">month</option>
        </select>. */}
        <br />
        <br />

        <a href="" className={styles.addButton + " hasTooltip"} onClick={(event) => {
          event.preventDefault();
          this.validateAndSubmit();
        }}>{this.state.editing ? "update" : "add"}<span className="tooltip">[enter]</span></a>
      </div>
    );
  }
}



export default HabitInput;