import '../css/App.scss';
import { toIsoDate } from '../DateHelper'
import Calendar from '../Calendar'

import React from 'react';
import { listHabits } from '../graphql/queries.js'
import { createHabit, deleteHabit, updateHabit, batchUpdateHabits } from '../graphql/mutations.js'
import { API, Auth, graphqlOperation } from 'aws-amplify'

import { withAuthenticator } from 'aws-amplify-react';


import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//TODO: is there a better data structure to store habits in?
//a dictionary would probably make looking up a habit by id much easier... 


class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      habits: [],
      checks: [],
      deleteModalOpen: false,
      deleting: -1,
      deletedHabits: []
    };
    this.swapHabits = this.swapHabits.bind(this);
    this.deleteHabit = this.deleteHabit.bind(this);
    this.addHabit = this.addHabit.bind(this);
    this.toggleCheck = this.toggleCheck.bind(this);
    this.updateHabit = this.updateHabit.bind(this);
  }

  async fetchHabits() {
    try {
      const habitData = await API.graphql(graphqlOperation(listHabits))
      let habits = habitData.data.listHabits.items;
      //TODO: you can probably configure your GraphQL queries to do this sort for you (GSI?)
      habits.sort(function (a, b) { return (a.ord > b.ord) ? 1 : ((b.ord > a.ord) ? -1 : 0) });
      this.setState({ habits: habits });
    } catch (err) {
      console.log('Error fetching habits:', err)
    }
  }
  
  showCreateToast(habit) {
    const n = () => {
      return (
        <div style={{ fontSize: "1rem" }}>
          <p>"{habit.name}" added.</p>
        </div>)}
    toast.dark(n, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
  }
  showDeleteToast(habit) {

    const n = () => {
      return (
        <div style={{ fontSize: "1rem" }}>
          <p>"{habit.name}" deleted.</p>
          <a style={{ color: "white" }}
            onClick={(e) => {
              e.preventDefault()
              this.addHabit(habit.name, habit.target, habit.repeat, habit.done, habit.createdAt, habit.ord);
              let deletedHabits = [... this.state.deletedHabits];
              deletedHabits.filter(h => h.id !== habit.id);
              this.setState({ deletedHabits: deletedHabits });

            }} href="">undo</a>
        </div>
      )
    }

    toast.dark(n, {
      position: "bottom-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
    });

  }

  async deleteHabit(habit) {

    try {
      let habits = [... this.state.habits]; 
      habits = habits.filter( h => h.id != habit.id);
      let deletedHabits = [... this.state.deletedHabits];
      deletedHabits.unshift(habit);
      this.setState({ deletedHabits: deletedHabits, habits:habits });
      
      this.showDeleteToast(habit);

      await API.graphql(graphqlOperation(deleteHabit, { input: { id: habit.id } }));
    } catch (err) {
      console.log('Error deleting habit:', err);
    }
  }


  async deleteCheck(id, date) {
    try {
      //what's the tradeoff of find + splice vs filter?
      let habits = [... this.state.habits];
      let habitIndex = habits.findIndex(h => h.id == id);
      let habit = habits[habitIndex]
      habit.done.splice(habit.done.indexOf(toIsoDate(date)), 1);

      this.setState({ habits: habits });

      await API.graphql(graphqlOperation(updateHabit, { input: { id: id, done: [...habit.done] } }))
    } catch (err) {
      console.log('Error deleting check:', err);
    }
  }

  async addHabit(habitName, habitTarget, habitRepeat, done = [], createdAt = toIsoDate(new Date()), ord = null) {
    try {
      if (ord === null) {
        if (this.state.habits.length > 0) {
          ord = this.state.habits[this.state.habits.length - 1].ord + 1
        }
      }

      let habit = { name: habitName, target: habitTarget, repeat: habitRepeat, done: done, ord: ord, createdAt: createdAt };
      let habits = [...this.state.habits];
      habits.push(habit);
      this.setState({ habits: habits });
      this.showCreateToast(habit);
      await API.graphql(graphqlOperation(createHabit, { input: habit }));
      this.fetchHabits();
    } catch (err) {
      console.log('Error creating habit:', err);
    }
  }

  async addCheck(id, date) {
    try {
      let habits = [... this.state.habits];
      let habitIndex = habits.findIndex(h => h.id == id);
      let habit = habits[habitIndex]
      habit.done.push(toIsoDate(date));
      this.setState({ habits: habits });
      await API.graphql(graphqlOperation(updateHabit, { input: { id: id, done: [...habit.done] } }))
    } catch (err) {
      console.log('error adding check:', err);
    }
  }

  toggleCheck(habitId, date, isChecked) {
    if (isChecked) {
      this.deleteCheck(habitId, date)
    } else {
      this.addCheck(habitId, date)
    }
  }

  async swapHabits(i, j) {
    try {
      let habits = [...this.state.habits];
      let temp = habits[i];
      habits.splice(i, 1);
      habits.splice(j, 0, temp);
      this.setState({ habits: habits })
      let last = 0;
      for (let k = 0; k < habits.length; k++) {
        habits[k].ord =  k ;
        delete habits[k].updatedAt;
        delete habits[k].owner;
        if ((k!== 0 && k%25 === 0) || k === habits.length - 1) {
          let end = Math.min(last + 25, habits.length+1);
          const toUpdate = {habits: habits.slice(last, end)};
          await API.graphql(graphqlOperation(batchUpdateHabits, toUpdate));
          last = end;
        }
      }

    

      // for (let k = 0; k < habits.length; k++) {
      //   let habit = { id: habits[k].id, ord: k };
      //   console.log("call:");
      //   await API.graphql(graphqlOperation(updateHabit, { input: habit }))
      //   console.log("order executed");
      // }
    } catch (err) {
      console.log('Error reordering habits:', err);
    }
  }

  async updateHabit(id, habitName, habitTarget, habitRepeat) {
    try {
      let habits = [... this.state.habits];
      let habit = habits.filter(h => h.id == id)[0];
      habit.name = habitName;
      habit.target = habitTarget;
      habit.repeat = habitRepeat;
      this.setState({ habits: habits });

      const updatedHabit = { id: id, name: habitName, target: habitTarget, repeat: habitRepeat };
      await API.graphql(graphqlOperation(updateHabit, { input: updatedHabit }))
      this.fetchHabits();

    } catch (err) {
      console.log('Error updating habit:', err);
    }
  }

  componentDidMount() {
    document.title = "Habit Tracker - home";
    this.fetchHabits();
  }

  render() {

    return (
      <>
        <nav>
          <ul>
            <li><a href="" onClick={function(e) {
              e.preventDefault(); 
              Auth.signOut()
              .then(d => console.log(d))
              .catch(err => console.log(err));
          }}>sign out</a></li>
            <li><a href="" onClick={(e)=>(e.preventDefault(), this.props.toAccountSettings())}>account</a></li>
          </ul>
        </nav>
        <div className="app">
          <h1>Habit Tracker</h1>
          <DeleteToastContainer/>
          <Calendar
            addHabit={this.addHabit}
            updateHabit={this.updateHabit}
            // deleteHabit={(id)=>this.setState({deleteModalOpen: true, deleting: this.state.habits.filter(h => h.id == id)[0] })}
            deleteHabit={this.deleteHabit}
            toggleCheck={this.toggleCheck}
            swapHabits={this.swapHabits}
            habits={this.state.habits}
          />
        </div>
      </>
    );
  }
}

const DeleteToastContainer = () => {
  return (
  <ToastContainer
    sposition="bottom-right"
    autoClose={5000}
    hideProgressBar
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />)
}

// export default HomePage;
export default withAuthenticator(HomePage, false);